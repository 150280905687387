<template>
  <div class="d-flex justify-space-between align-center wrapper">
    <div
      v-if="!isMobile"
      class="markero-logo col-6 pa-0 px-10"
    >
      <v-img
        alt="Company Registration Logo"
        :src="require('@/../public/img/markero-registration.png')"
        contain
      />
    </div>
    <div class="h-full col-md-6 flex-column d-flex justify-space-between">
      <ProductFinder />
      <div class="d-flex flex-wrap justify-center">
        <IubendaDocumentLink
          type="terms"
          class="mx-1 mx-sm-2"
          @click.stop
        />
        <IubendaDocumentLink
          class="mx-1 mx-sm-2"
          type="privacyPolicy"
          @click.stop
        />
        <IubendaConsentLink class="mx-1 mx-sm-2" />
        <a
          :href="imprintUrl"
          target="_blank"
          class="mx-1 mx-sm-2"
          rel="noopener noreferrer"
        >{{ $t('navigation.labels.imprint') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import IubendaDocumentLink from '@/modules/iubenda/DocumentLink.vue'
import IubendaConsentLink from '@/modules/iubenda/ConsentPreferencesLink.vue'
import brandingMixin from '@/mixins/branding'

export default {
  components: {
    IubendaConsentLink,
    IubendaDocumentLink,
    ProductFinder: () => import('@/components/product-finder/ProductFinder.vue')
  },
  mixins: [brandingMixin],
  computed: {
    isMobile () {
      return this.$vuetify.breakpoint.xsOnly
    }
  }
}

</script>

<style scoped>
  .wrapper {
    background-color: white;
  }
  .wrapper, .markero-logo {
    height: 100vh;
  }
  .markero-logo {
    background-color: #F9B004;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
</style>
